<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="datepicker1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      autocomplete="off"
                      :value="formatDate(devis.datedevis)"
                      @click:clear="devis.datedevis = null"
                      label="Date devis"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      class="pa-0"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="devis.datedevis"
                    @input="datepicker1 = false"
                    :max="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="6" md="8">
                    <v-autocomplete
                      :items="client_list"
                      v-model="devis.client_id"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v || 'Client obligatoire']"
                      label="Client"
                      @change="notechange(devis.client_id)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-btn
                      small
                      color="indigo"
                      @click.stop="showScheduleForm = true"
                      class="mt-5"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      dense
                      chips
                      v-model="devis.notation"
                      label="Notation"
                      required
                      :rules="[(v) => !!v || 'Notation obligatoire']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      autocomplete="off"
                      dense
                      v-model="devis.produit"
                      label="Produit"
                      required
                      :rules="[(v) => !!v || 'Produit obligatoire']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.qte"
                      label="Quantité"
                      required
                      :rules="[
                        (v) => !!v || 'Quantité obligatoire',
                        (v) =>
                          v >= parametres[0].qtemin ||
                          'Quantité min ' + parametres[0].qtemin,
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      :items="support_list"
                      v-model="devis.support_id"
                      item-text="support"
                      item-value="id"
                      :rules="[(v) => !!v || 'Support obligatoire']"
                      label="Support"
                      @change="supportchange(devis.support_id)"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="2" md="4">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.al"
                      :rules="[
                        (v) => !!v || 'Al obligatoire',
                        (v) => v > 0 || 'invalide valeur',
                      ]"
                      label="Al"
                      suffix="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="4">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.aq"
                      :rules="[
                        (v) => !!v || 'Aq obligatoire',
                        (v) => v > 0 || 'invalide valeur',
                      ]"
                      label="Aq"
                      suffix="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="4">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.rives"
                      :rules="[
                        (v) => !!v || 'Rives obligatoire',
                        (v) => v > 0 || 'invalide valeur ',
                      ]"
                      label="Rives"
                      suffix="mm"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" md="2"></v-col>
                  <v-col cols="12" sm="3" md="2">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.laize"
                      :rules="[
                        (v) => !!v || 'Laize obligatoire',
                        (v) => v > 0 || 'invalide valeur',
                      ]"
                      label="Laize"
                      suffix="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="8"></v-col>
                  <v-col cols="12" sm="3" md="2">
                    <v-text-field
                      autocomplete="off"
                      class="inputPrice"
                      type="number"
                      dense
                      v-model="devis.dev"
                      :rules="[
                        (v) => !!v || 'Dev obligatoire',
                        (v) => v > 0 || 'invalide valeur',
                      ]"
                      label="Dev"
                      suffix="mm"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-card
                      elevation="5"
                      height="150"
                      width="100"
                      color="orange"
                    >
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-textarea
                      dense
                      v-model="devis.comment"
                      label="Commentaire"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="mb-6">
                <v-container fill-height>
                  <v-layout row justify-center align-center v-if="progress">
                    <v-progress-circular
                      indeterminate
                      :size="30"
                      :width="7"
                      color="purple"
                    ></v-progress-circular>
                  </v-layout>
                </v-container>
                <v-btn
                  v-if="devis.status_id == 'Brouillon'"
                  class="mx-2"
                  color="indigo"
                  @click="valider"
                  :disabled="pu.length == 0"
                >
                  Valider
                </v-btn>

                <v-btn
                  class="mx-2"
                  color="indigo"
                  @click="imprimer"
                  :disabled="pu.length == 0"
                >
                  Imprimer
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="devis.status_id == 'Brouillon'"
                  class="mx-2"
                  color="indigo"
                  @click="save"
                >
                  Save
                </v-btn>
                <v-btn class="mx-2" color="indigo" @click="close">
                  Fermer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </div>
        <div>
          <v-row class="mt-1">
            <v-col cols="12" sm="6" md="12">
              <!--v-card>
                <v-card-title>
                  <span>Suggetion</span>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <p class="font-weight-bold d-inline    ">
                      Etq/Rev : {{ etq_sug }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <p class="font-weight-bold d-inline    ">
                      Surf : {{ surf_sug }}
                    </p>
                  </v-col>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4" md="8">
                      <v-row no-gutters>
                        <v-col
                          sm="2"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Laize
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="font-weight-bold d-inline"
                        >
                          {{ Sugg.laize }}
                        </v-col>
                        <v-col
                          sm="3"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Etq/Laize </v-col
                        ><v-col
                          cols="12"
                          sm="1"
                          md="2"
                          class="font-weight-bold d-inline"
                        >
                          {{ Sugg.nblaize }} </v-col
                        ><v-col
                          sm="2"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Aq
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="font-weight-bold d-inline "
                          >{{ Sugg.aq }}</v-col
                        >
                        <v-col
                          sm="2"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Dev
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="font-weight-bold d-inline"
                          >{{ Sugg.dev }}</v-col
                        >
                        <v-col
                          sm="3"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Etq/dev </v-col
                        ><v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="font-weight-bold d-inline"
                        >
                          {{ Sugg.nbdev }}</v-col
                        ><v-col
                          sm="2"
                          md="2"
                          class="black white--text text-center secondary text-no-wrap"
                          >Al
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          class="font-weight-bold d-inline"
                        >
                          {{ Sugg.al }}</v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card-->
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <v-card>
              <v-card-title>
                <span>Selection</span>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4" md="3">
                  <p class="font-weight-bold d-inline">
                    Etq/Rev : {{ etq_sel }}
                  </p>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <p class="font-weight-bold d-inline">Surf : {{ surf_sel }}</p>
                </v-col>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="4" md="6">
                    <v-row>
                      <v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Laize : {{ selected_laize[0].laize }}
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Etq/Laize : {{ selected_laize[0].etq }}
                        </p> </v-col
                      ><v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Aq : {{ devis.aq }}
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Dev : {{ selected_dev[0].dev }}
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Etq/Dev : {{ selected_dev[0].etq }}
                        </p> </v-col
                      ><v-col cols="12" sm="4" md="4"
                        ><p class="font-weight-bold d-inline">
                          Al : {{ selected_dev[0].al }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" sm="4" md="6">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <p
                          class="text-h6 font-weight-bold d-inline py-2 px-4 black green--text"
                        >
                          PU :
                        </p>

                        <p
                          class="text-h6 font-weight-bold d-inline pa-2 green black--text"
                        >
                          {{ pu }}
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4" md="6">
                        <p
                          class="text-h6 font-weight-bold d-inline pa-2 black yellow--text"
                        >
                          Fact :
                        </p>

                        <p
                          class="text-h6 font-weight-bold d-inline pa-2 yellow black--text"
                        >
                          {{ facteur }}
                        </p>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <p
                          class="text-h6 font-weight-bold d-inline pa-2 black green--text"
                        >
                          Total :
                        </p>

                        <p
                          class="text-h6 font-weight-bold d-inline pa-2 green black--text"
                        >
                          {{
                            Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD",
                            }).format(total)
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-1">
          <v-card>
            <v-card-title>
              <span class="headline">Variantes</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <span>Laizes</span>
                  <v-data-table
                    fixed-header
                    class="elevation-1"
                    dense
                    single-select
                    :items="calclaizes"
                    :headers="laizeheaders"
                    v-model="selected_laize"
                    item-key="etq"
                    @click:row="rowClick"
                    :item-class="itemRowBackground"
                  >
                  </v-data-table>
                </v-col>
                <v-col>
                  <span>Devs</span>
                  <v-data-table
                    fixed-header
                    class="elevation-1"
                    dense
                    single-select
                    :items="calcdev"
                    :headers="devheaders"
                    v-model="selected_dev"
                    @click:row="rowClick"
                    item-key="etq"
                    :item-class="itemRowBackground2"
                  >
                  </v-data-table
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <template>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        top
        :color="snackbar_color"
      >
        {{ snackbar_text }}
      </v-snackbar>
    </template>
    <clientform
      v-model="showScheduleForm"
      :client_id="-1"
      :editedItem="editedClient"
    >
    </clientform>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
const clientform = () => import("../components/ClientForm.vue");
const confirmdialog = () => import("../components/ConfirmDialog.vue");

import CREATE_DEVIS from "../graphql/Devis/CREATE_DEVIS.gql";
import { mapState } from "vuex";
//import UPDATE_DEVIS from "../graphql/Devis/UPDATE_DEVIS.gql";

export default {
  components: {
    clientform,
    confirmdialog,
  },

  data: () => ({
    showScheduleForm: false,
    dev: "",
    editedClient: {
      id: "",
      name: "",
      notation: "",
      email: "",
      tel: "",
    },

    closeOnContentClick: false,
    valid: true,
    datepicker1: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    indexdevis: "",

    selected_laize: [{ laize: "", etq: "" }],
    selected_dev: [{ dev: "", etq: "", al: "" }],
    calclaizes: [],
    calcdev: [],
    laizeheaders: [
      {
        text: "Nb etq",
        value: "etq",
      },
      {
        text: "Laize",
        value: "laize",
      },
      {
        text: "Chutte / etq",
        value: "cht",
      },
    ],
    devheaders: [
      {
        text: "Nb etq",
        value: "etq",
      },
      {
        text: "Dev",
        value: "dev",
      },
      {
        text: "Al",
        value: "al",
      },
    ],
    devis: [],
    editedIndex: -1,
    codedevis: "",

    defaultItem: {
      notation: "",
      client_id: "",
      datedevis: null,
      produit: "",
      laize: "",
      dev: "",
      nodevis: "",
      qte: "",
      support_id: "",
      status_id: "Brouillon",
      al: "",
      aq: "",
      rives: "",
      pu: "0",
      pusupport: "",
      devisclients: {
        name: "",
      },
      support: {
        support: "",
      },
    },
    Sugg: {
      laize: "",
      nblaize: "",
      aq: "",
      dev: "",
      nbdev: "",
      al: "",
    },
    facteur: "",
    pu: "",
    total: "",
    surf_sel: "",
    etq_sel: "",
    surf_sug: "",
    etq_sug: "",
    change: 0,
  }),

  computed: {
    ...mapState(["devs", "client_list", "parametres", "support_list"]),

    laizes() {
      return this.$store.state.laizes.filter(
        (element) => element.support_id == this.devis.support_id
      );
    },

    puchange() {
      // `.join()` because we don't care about the return value.
      this.devis.rives;
      this.devis.laize;
      this.devis.aq;
      this.devis.dev;
      this.devis.al;
      this.devis.notation;
      this.devis.qte;
      this.devis.pu_support;
      this.selected_laize[0].etq;
      this.selected_dev[0].etq;

      return Date.now();
    },
    laizechange() {
      // `.join()` because we don't care about the return value.

      this.devis.rives;
      this.devis.laize;
      this.devis.aq;

      return Date.now();
    },
    devchange() {
      // `.join()` because we don't care about the return value.
      this.devis.dev;
      this.devis.al;

      return Date.now();
    },
    selection() {
      return this.headers.filter((item) => {
        if (item.selected === true) {
          return item;
        }
      });
    },

    formTitle() {
      return this.$route.params.id == -1
        ? "Nouveau DEVIS N° " + this.$store.state.newdevis
        : "Editer DEVIS N° " + this.devis.nodevis;
    },
    isDisable() {
      return this.Newdevis != "";
    },
    progress() {
      return this.$store.state.progress;
    },
  },
  watch: {
    "$store.state.newclient": {
      handler() {
        this.client_list.push(this.$store.state.newclient);
        this.devis.client_id = this.$store.state.newclient.id;
      },
      deep: true,
    },

    laizechange: {
      handler() {
        this.change += 1;
        if (
          this.devis.laize.length == 0 ||
          this.devis.rives.length == 0 ||
          this.devis.aq.length == 0
        ) {
          this.selected_laize = [{ laize: "", etq: "" }];
          this.selected_dev = [{ dev: "", etq: "", al: "" }];
          this.etq_sel = "";
          this.surf_sel = "";
        }

        this.CalculLaize();
      },
      deep: true,
    },

    puchange: {
      handler() {
        this.Calculpu();
      },
      deep: true,
    },
    devchange: {
      handler() {
        this.change += 1;
        if (this.devis.dev.length == 0 || this.devis.al.length == 0) {
          this.selected_laize = [{ laize: "", etq: "" }];
          this.selected_dev = [{ dev: "", etq: "", al: "" }];
          this.etq_sel = "";
          this.surf_sel = "";
        }
        this.CalculDev();
      },
      deep: true,
    },

    "devis.support_id": {
      handler() {
        this.change += 1;
        this.CalculLaize();
        if (this.devs.length == 0 || this.laizes.length == 0) {
          this.selected_laize = [{ laize: "", etq: "" }];
          this.selected_dev = [{ dev: "", etq: "", al: "" }];
          this.etq_sel = "";
          this.surf_sel = "";
        }
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  mounted() {},

  methods: {
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    imprimer() {},
    async valider() {
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.confirm.open(
            "Validation",
            "Etês-vous sûr de vouloir valider ce devis?"
          )
        ) {
          this.devis.status_id = "Valide";
          this.save();
        }
      }
    },
    itemRowBackground: function (item) {
      if (item.etq == this.Sugg.nblaize) return "style-1";
    },
    itemRowBackground2: function (item) {
      if (item.etq == this.Sugg.nbdev) return "style-1";
    },
    notechange(client_id) {
      this.devis.notation = this.client_list.find(
        (element) => element.id == client_id
      ).notation;
      this.devis.devisclients.name = this.client_list.find(
        (element) => element.id == client_id
      ).name;
    },
    supportchange(support_id) {
      this.devis.pu_support = this.support_list.find(
        (element) => element.id == support_id
      ).prix;
      this.devis.support.support = this.support_list.find(
        (element) => element.id == support_id
      ).support;
    },

    getMax(arr, prop) {
      var max;
      for (var i = 0; i < arr.length; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
          max = arr[i];
      }
      return max;
    },
    predicateBy(prop) {
      return function (a, b) {
        if (parseFloat(a[prop]) > parseFloat(b[prop])) {
          return -1;
        } else if (parseFloat(a[prop]) < parseFloat(b[prop])) {
          return 1;
        }
        return 0;
      };
    },
    CalculDev() {
      this.calcdev = [];
      this.Sugg.laize = "";
      this.Sugg.nblaize = "";

      if (this.devis.dev > 0 && this.devis.al > 0 && this.devs.length > 0) {
        let devMax = this.getMax(this.devs, "devmm").devmm;
        let dev = this.devis.dev;
        let al = this.devis.al;
        var etq = 1;
        let devpos, newal;
        var devreq = dev * etq + al * etq;

        while (devreq <= devMax) {
          devpos = this.devs.find((element) => element.devmm >= devreq);
          newal = (devpos.devmm - devreq) / etq + parseFloat(al);

          this.calcdev.push({
            dev: devpos.devmm.toFixed(2),
            etq: etq,
            al: newal.toFixed(3),
          });
          etq = etq + 1;
          devreq = dev * etq + al * etq;
        }

        this.calcdev.sort(this.predicateBy("etq"));
        this.CalculSurf();
      }
    },

    CalculLaize() {
      this.calclaizes = [];

      this.Sugg.dev = "";
      this.Sugg.nbdev = "";
      this.Sugg.al = "";
      this.etq_sel = "";
      this.surf_sel = "";
      if (
        this.devis.laize > 0 &&
        this.devis.aq > 0 &&
        this.devis.rives > 0 &&
        this.laizes.length > 0
      ) {
        let laizeMax = this.getMax(this.laizes, "laize").laize;

        let laize = this.devis.laize;
        let aq = this.devis.aq;
        let rmax = this.devis.rives;

        var etq = 1;
        let laizepos, chutte;
        var laizereq = 0;
        laizereq = laize * etq + aq * (etq - 1) + parseFloat(rmax);

        while (laizereq <= laizeMax) {
          laizepos = this.laizes.find((element) => element.laize >= laizereq);
          chutte = (laizepos.laize - laizereq) / etq;

          this.calclaizes.push({
            laize: laizepos.laize.toFixed(2),
            etq: etq,
            cht: chutte.toFixed(3),
          });
          etq = etq + 1;
          laizereq = laize * etq + aq * (etq - 1) + parseFloat(rmax);
        }

        this.calclaizes.sort(this.predicateBy("etq"));
        this.CalculSurf();
      }
    },
    CalculSurf() {
      if (this.calcdev.length > 0 && this.calclaizes.length > 0) {
        var minsurf = -1;
        var devmin, laizemin;
        for (const dev of this.calcdev) {
          for (const laize of this.calclaizes) {
            let surf =
              ((dev.dev / 1000) * (laize.laize / 1000)) / (dev.etq * laize.etq);
            if (minsurf == -1 || surf < minsurf) {
              minsurf = surf;
              devmin = dev.etq;
              laizemin = laize.etq;
            }
          }
        }
        let i = this.calcdev.findIndex((element) => element.etq == devmin);
        let j = this.calclaizes.findIndex((element) => element.etq == laizemin);
        this.Sugg.dev = this.calcdev[i].dev;
        this.Sugg.nbdev = this.calcdev[i].etq;
        this.Sugg.al = this.calcdev[i].al;
        this.Sugg.aq = this.devis.aq;
        this.Sugg.laize = this.calclaizes[j].laize;
        this.Sugg.nblaize = this.calclaizes[j].etq;
        if (this.change > 3) {
          this.selected_dev = [];
          this.selected_dev.push({
            dev: this.calcdev[i].dev,
            etq: this.calcdev[i].etq,
            al: this.calcdev[i].al,
          });
          this.selected_laize = [];
          this.selected_laize.push({
            laize: this.calclaizes[j].laize,
            etq: this.calclaizes[j].etq,
            al: this.calclaizes[j].cht,
          });
        }
        this.etq_sel = this.selected_laize[0].etq * this.selected_dev[0].etq;
        this.surf_sel =
          ((this.selected_laize[0].laize / 1000) *
            (this.selected_dev[0].dev / 1000)) /
          this.etq_sel;
        this.surf_sel = this.surf_sel.toFixed(4);
      }
    },
    Calculpu() {
      this.pu = "";
      this.total = "";
      this.facteur = "";

      if (
        this.selected_laize[0].laize &&
        this.selected_dev[0].dev &&
        this.selected_laize[0].etq &&
        this.selected_dev[0].etq &&
        this.devis.qte >= this.parametres[0].qtemin &&
        this.devis.notation
      ) {
        this.etq_sel = this.selected_laize[0].etq * this.selected_dev[0].etq;
        this.surf_sel =
          ((this.selected_laize[0].laize / 1000) *
            (this.selected_dev[0].dev / 1000)) /
          this.etq_sel;

        let cu_sel =
          this.surf_sel *
          this.devis.pu_support *
          this.parametres[0].tauxencre *
          this.parametres[0].tauxperte;

        this.etq_sug = this.Sugg.nblaize * this.Sugg.nbdev;
        this.surf_sug =
          ((this.Sugg.laize / 1000) * (this.Sugg.dev / 1000)) / this.etq_sug;

        /*let cu_sug =
          this.surf_sug *
          this.devis.pu_support *
          this.parametres[0].tauxencre *
          this.parametres[0].tauxperte;
        let perte = ((cu_sel - cu_sug) / cu_sug) * 100;
        perte = Math.round((perte + Number.EPSILON) * 100) / 100;*/

        this.pu =
          (cu_sel *
            (this.devis.qte * this.parametres[0].facteurmin +
              this.parametres[0].qtemin *
                (this.parametres[0].facteurmax -
                  this.parametres[0].facteurmin) *
                (1 - this.devis.notation / 10))) /
          this.devis.qte;

        this.facteur = this.pu / cu_sel;
        cu_sel = cu_sel.toFixed(3);
        //this.pu = Math.round((this.pu + Number.EPSILON) * 100) / 100;

        this.pu = this.pu.toFixed(2);
        this.total = this.pu * this.devis.qte;
        this.total = this.total.toFixed(2);
        this.facteur = this.facteur.toFixed(3);
        this.surf_sel = this.surf_sel.toFixed(4);
        this.surf_sug = this.surf_sug.toFixed(4);
      }
    },

    initialize() {
      if (this.$route.params.id == -1) {
        this.defaultItem.al = this.parametres[0].al;
        this.defaultItem.aq = this.parametres[0].aq;
        this.defaultItem.rives = this.parametres[0].rives;
        this.defaultItem.qte = this.parametres[0].qtemin;
        this.defaultItem.nodevis = this.$store.state.newdevis;
        this.defaultItem.datedevis = this.$store.state.today;
        this.devis = Object.assign({}, this.defaultItem);
      } else {
        this.devis = this.$store.state.devislist.find(
          (dev) => dev.id == this.$route.params.id
        );

        this.indexdevis = this.$store.state.devislist.findIndex(
          (dev) => dev.id == this.$route.params.id
        );
        if (this.devis.nb_dev) {
          this.selected_dev = [];
          this.selected_dev.push({
            dev: this.devis.cylindre_dev,
            etq: this.devis.nb_dev,
            al: this.devis.new_al,
          });
        }
        if (this.devis.nb_laize) {
          this.selected_laize = [];
          this.selected_laize.push({
            laize: this.devis.support_laize,
            etq: this.devis.nb_laize,
            cht: this.devis.aq,
          });
        }
      }
    },

    close() {
      this.$router.push("/listedevis");
    },

    save() {
      if (this.$refs.form.validate()) {
        //this.progress = true;
        if (this.selected_dev.length > 0) {
          this.devis.cylindre_dev = this.selected_dev[0].dev;
          this.devis.nb_dev = this.selected_dev[0].etq;
          this.devis.new_al = this.selected_dev[0].al;
        }
        if (this.selected_laize.length > 0) {
          this.devis.support_laize = this.selected_laize[0].laize;
          this.devis.nb_laize = this.selected_laize[0].etq;
        }
        this.devis.pu = this.pu;
        this.devis.facteur = this.facteur;
        this.devis.user = this.$store.state.me.id;
        if (this.$route.params.id != -1) {
          /* this.$apollo
            .mutate({
              mutation: UPDATE_DEVIS,
              variables: {
                id: this.devis.id,

                notation: this.devis.notation,
                client_id: this.devis.client_id,
                datedevis: this.devis.datedevis,
                produit: this.devis.produit,
                laize: parseFloat(this.devis.laize),
                dev: parseFloat(this.devis.dev),
                nodevis: this.devis.nodevis,
                qte: parseFloat(this.devis.qte),
                support_id: this.devis.support_id,
                status_id: this.devis.status_id,
                comment: this.devis.comment,
                pu: parseFloat(this.pu),
                facteur: parseFloat(this.facteur),

                al: parseFloat(this.devis.al),
                aq: parseFloat(this.devis.aq),
                rives: parseFloat(this.devis.rives),
                pu_support: parseFloat(this.devis.pu_support),

                support_laize: parseFloat(this.devis.support_laize),
                nb_laize: parseInt(this.devis.nb_laize),
                cylindre_dev: parseFloat(this.devis.cylindre_dev),
                nb_dev: parseInt(this.devis.nb_dev),
                new_al: parseFloat(this.devis.new_al),

                write_uid: this.$store.state.me.id,
              },
            })
            .then(() => {
              this.progress = false;
              this.snackbar_text = "Update success";
              this.snackbar_color = "success";
              this.snackbar = true;
              //this.$store.state.devislist.splice(this.indexdevis, 1, this.devis);

              this.$store.dispatch("UPDATE_DEVIS", this.devis);
            })
            .catch((error) => {
              this.progress = false;
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });*/
          this.$store.dispatch("UPDATE_DEVIS", this.devis);
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_DEVIS,
              variables: {
                notation: this.devis.notation,
                client_id: this.devis.client_id,
                datedevis: this.devis.datedevis,
                produit: this.devis.produit,
                laize: parseFloat(this.devis.laize),
                dev: parseFloat(this.devis.dev),
                nodevis: this.devis.nodevis,
                qte: parseFloat(this.devis.qte),
                support_id: this.devis.support_id,
                status_id: this.devis.status_id,
                comment: this.devis.comment,
                pu: parseFloat(this.pu),
                facteur: parseFloat(this.facteur),

                al: parseFloat(this.devis.al),
                aq: parseFloat(this.devis.aq),
                rives: parseFloat(this.devis.rives),
                pu_support: parseFloat(this.devis.pu_support),
                support_laize: parseFloat(this.devis.support_laize),
                nb_laize: parseInt(this.devis.nb_laize),
                cylindre_dev: parseFloat(this.devis.cylindre_dev),
                nb_dev: parseInt(this.devis.nb_dev),
                new_al: parseFloat(this.devis.new_al),

                write_uid: this.$store.state.me.id,
                create_uid: this.$store.state.me.id,
              },
            })
            .then((data) => {
              this.progress = false;
              this.snackbar_color = "success";
              this.snackbar_text = "Add success";
              this.snackbar = true;
              this.devis.id = data.data.createDevis.id;
              //this.$store.dispatch('ADD_DEVIS',this.devis)
              this.$store.state.devislist.unshift(this.devis);
              this.$route.params.id = this.devis.id;
            })
            .catch((error) => {
              this.progress = false;
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
      }
    },

    rowClick(item, row) {
      row.select(true);
    },
  },
};
</script>
